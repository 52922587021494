import "./App.css";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./component/DashboardNew";
import Login from "./component/Login/login";
import Landing from "./component/Contest/Index";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="wrapper">
        {/* <Nav userName={userName} isLogout={isLogout} /> */}

        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" exact component={() => <Dashboard />} />
          </Switch>
        </BrowserRouter>
      </div>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}
export default App;
