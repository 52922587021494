import axios from "axios";
import commonUrl from "services/urls";

axios.defaults.baseURL = commonUrl.base_url;

export function initAxios() {
  axios.interceptors.request.use(
    (request) => {
      request.headers = {
        ...request.headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const token = localStorage.getItem("token");
      if (token) {
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return request;
    },
    (error) => Promise.reject(error)
  );
}

export const login = (payload) => {
  return axios({
    url: "api/login",
    method: "POST",
    data: payload,
  }).then(({ data }) => data.data);
};

export const getContestNames = () => {
  return axios({
    url: "api/dashboard", // get contest name
    method: "POST",
  }).then(({ data }) => data.data.collection);
};

export const getShTotal = (shortlistId, contestId) => {
  return axios({
    url: "api/dashboard/total", // totol shortlisted count
    method: "POST",
    data: {
      CollectionId: shortlistId,
      ShortlistId: contestId,
    },
  }).then(
    ({ data }) =>
      data.data || {
        contestId: 0,
        shortlistId: 0,
        totalCount: 0,
        shortCount: 0,
        contestName: "",
        userId: 0,
      }
  );
};

export const getPosition = (contestId, shortlistId) => {
  return axios({
    url: "api/position",
    method: "POST",
    data: {
      CollectionId: contestId,
      ShortlistId: shortlistId,
    },
  }).then(({ data }) => data.data.position || 0);
};

export const getPending = (position) => {
  return axios({
    url: "login/pending/" + position, // userid/shortlist
    method: "POST",
  }).then(({ data }) => data);
};

export const updatePosition = (payload) => {
  return axios({
    url: "api/update-position", // artwork posistion
    method: "POST",
    data: payload,
  }).then(({ data }) => data);
};

export const finalShortList = (payload) => {
  return axios({
    url: "api/final/submit", // master shortlist
    method: "POST",
    data: payload,
  }).then(({ data }) => data);
};

export const shortlistLevel = (payload) => {
  return axios({
    url: "api/sortlist/store", // shortlist level
    method: "POST",
    data: payload,
  }).then(({ data }) => data);
};
//login/newshortlist

export const newShortList = (payload) => {
  return axios({
    url: "api/sortlist", //invidual artwork sortlist
    method: "POST",
    data: payload,
  }).then(({ data }) => data);
};

export const discard = (payload) => {
  //login/discard
  return axios({
    url: "login/discard", //no need
    method: "POST",
    data: payload,
  }).then(({ data }) => data);
};

export const getUserDetails = () => {
  return axios({
    url: "login/users", // get user details
    method: "POST",
  }).then(({ data }) => data);
};

export const getArtworks = (contestId, shortlistId) => {
  return axios({
    url: "api/artwork", // get user details
    method: "POST",
    data: {
      CollectionId: contestId,
      ShortlistId: shortlistId,
    },
  }).then(({ data }) => data.data);
};

export const getFinalShorlistedArtworks = (contestId, shortlistId) => {
  return axios({
    url: "login/artwork/" + contestId + "/3", // get user details
    method: "POST",
  }).then(({ data }) => data);
};

export const postParticipantArtwork = (postData) => {
  return axios({
    url: "api/participant-artwork",
    method: "POST",
    data: postData,
    header: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  }).then(({ data }) => data || 0);
};
