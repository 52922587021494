function Header() {
  return (
    <header className="banner">
      <div className="container-fluid h-100">
        <div className="row h-100 align-items-center p-5">
          <div className="col-lg-3 col-md-5 col-sm-12 text-center text-white banner-box p-3">
            <h1 className="">
              The Artoreal Photography Awards <span>Summer 2024</span>
            </h1>
            <h2 className="fw-light">
              15<sup>th</sup> March - 30<sup>th</sup> April  2024
            </h2>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
