import AwardCard from "./AwardCard";
import price1 from "./assets/Prize1.png";
import price2 from "./assets/Prize2.png";
import price3 from "./assets/Prize3.png";
import price4 from "./assets/Prize4.png";
import price5 from "./assets/Prize5.png";

function Award() {
  return (
    <section className="award-section">
      <div className="container-fluid p-5">
        <div className="row m-0 pt-3 justify-content-center">
          <h1 className="section-title text-center text-uppercase">
            Awards And Prizes
          </h1>

          <AwardCard
            image={price1}
            title={"Photo of the season"}
            price={"&#8377; 20,000"}
            countertext={"1 Prize"}
          />

          <AwardCard
            image={price2}
            title={"Gold prize"}
            price={"&#8377; 10,000"}
            countertext={"1 Prize"}
          />

          <AwardCard
            image={price3}
            title={"Silver prize"}
            price={"&#8377; 5,000"}
            countertext={"1 Prize"}
          />

          <AwardCard
            image={price4}
            title={"Bronze prize"}
            price={"&#8377; 2,500"}
            countertext={"1 Prize"}
            classes={"no-border second-last"}
          />

          <AwardCard
            image={price5}
            title={"Runners up"}
            price={"certificate"}
            countertext={"remaining top 100"}
            classes={"no-border last"}
          />
        </div>

        <p className="text-center text-muted">
          All winners will be given a certificate along with the award
          <br />
          All winners will be invited to enrol on Artoreal.com the popular
          e-commerce platform for listing, exhibiting and monetising your
          photographs
        </p>
      </div>
    </section>
  );
}

export default Award;
