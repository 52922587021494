import logo from "./../../assets/Artoreal_poppins.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useIpadQuery = () => {
  const isResponsiveDevice = useMediaQuery("(max-width:960px)");
  return isResponsiveDevice;
};
export const useMobileQuery = () => {
  const isResponsiveDevice = useMediaQuery("(max-width:767px)");
  return isResponsiveDevice;
};
function TopNavbar() {
  const isResponsiveDevice = useIpadQuery();
  return (
    <nav className="navbar navbar-expand-md navbar-dark justify-content-center">      
    <a className="navbar-brand d-md-none center" href="/">
        <img 
            width={isResponsiveDevice ? 112 : 230}
            height={isResponsiveDevice ? 41 : 90}
            src={logo} 
            alt="" 
            style={{
                width: isResponsiveDevice ? "112px" : "230px",
                height: isResponsiveDevice ? "41px" : "90px"
            }}
        />
    </a>
    <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mx-auto">
            <a className="navbar-brand d-none d-md-block" href="/">
                <img 
                    src={logo} 
                    alt="" 
                    width="270"
                    height="90"
                />
            </a>
        </ul>
    </div>
</nav>

  );
}

export default TopNavbar;
