import AlertComponent from "component/Alert";
import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useQuery } from "react-query";
import { postParticipantArtwork } from "services/apiCall";
import loading from "assets/loading.gif";
import { FaRedoAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import CountryCodeOption from "./CountryCodeOption";

const SubmitWork = () => {
  const [artwork, setArtwork] = useState({
    cultural_theme_camera: "",
    cultural_theme_description: "",
    cultural_theme_file: "",
    cultural_theme_location: "",
    cultural_theme_title: "",
    cultural_theme_year: "",
    destination_theme_camera: "",
    destination_theme_description: "",
    destination_theme_file: "",
    destination_theme_location: "",
    destination_theme_title: "",
    destination_theme_year: "",
    water_theme_camera: "",
    water_theme_description: "",
    water_theme_file: "",
    water_theme_location: "",
    water_theme_title: "",
    water_theme_year: "",
    participant_email: "",
    participant_mobile: "",
    participant_name: "",
    accepted: "",
    participant_country_code: "91",
  });

  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("");
  const [waterRequied, setWaterRequied] = useState(false);
  const [destinationRequied, setDestinationRequied] = useState(false);
  const [culturalRequied, setCulturalRequied] = useState(false);
  const refWaterFile = useRef();
  const refDestinationFile = useRef();
  const refCulturalFile = useRef();
  const refCountryCodeSelect = useRef();

  const postParticipantArtworkApiQuery = React.useCallback(() => {
    //console.log("postParticipantArtworkApiQuery :::");

    const data = new FormData();

    data.append("cultural_theme_camera", artwork.cultural_theme_camera);
    data.append(
      "cultural_theme_description",
      artwork.cultural_theme_description
    );
    data.append("cultural_theme_file", artwork.cultural_theme_file);
    data.append("cultural_theme_location", artwork.cultural_theme_location);
    data.append("cultural_theme_title", artwork.cultural_theme_title);
    data.append("cultural_theme_year", artwork.cultural_theme_year);
    data.append("destination_theme_camera", artwork.destination_theme_camera);
    data.append(
      "destination_theme_description",
      artwork.destination_theme_description
    );
    data.append("destination_theme_file", artwork.destination_theme_file);
    data.append(
      "destination_theme_location",
      artwork.destination_theme_location
    );
    data.append("destination_theme_title", artwork.destination_theme_title);
    data.append("destination_theme_year", artwork.destination_theme_year);
    data.append("water_theme_camera", artwork.water_theme_camera);
    data.append("water_theme_description", artwork.water_theme_description);
    data.append("water_theme_file", artwork.water_theme_file);
    data.append("water_theme_location", artwork.water_theme_location);
    data.append("water_theme_title", artwork.water_theme_title);
    data.append("water_theme_year", artwork.water_theme_year);
    data.append("participant_email", artwork.participant_email);
    data.append(
      "participant_mobile",
      "+" + artwork.participant_country_code + artwork.participant_mobile
    );
    data.append("participant_name", artwork.participant_name);
    data.append("accepted", artwork.accepted);

    return postParticipantArtwork(data);
  }, [artwork]);

  const loginSuccess = React.useCallback((data) => {
    //console.log("loginSuccess :::", data);

    resetForm("water");
    resetForm("destination");
    resetForm("cultural");

    let resetArtwork = artwork;
    // resetArtwork["participant_email"] = "";
    // resetArtwork["participant_mobile"] = "";
    // resetArtwork["participant_name"] = "";
    resetArtwork["accepted"] = "";
    setArtwork(resetArtwork);

    //setMessage(data.message);
    //setMessageVariant("success");

    Swal.fire({
      icon: "success",
      title: data.message,
    });
  }, []);

  const loginError = React.useCallback((e) => {
    //console.log("loginError :::", e, e.response);

    //setMessageVariant("danger");

    if (e.response.status === 422) {
      //setMessage(e.response.data.data);
      Swal.fire({
        icon: "error",
        //title: 'Oops...',
        text: e.response.data.data,
      });
      return;
    }
    //setMessage(e);
    Swal.fire({
      icon: "error",
      //title: 'Oops...',
      text: e,
    });
  }, []);

  const { isLoading, refetch } = useQuery(
    "login",
    postParticipantArtworkApiQuery,
    {
      enabled: false,
      retry: 0,
      onSuccess: loginSuccess,
      onError: loginError,
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );

  //console.log("isLoading", isLoading);

  const handleSubmit = (event) => {
    //console.log("handleSubmit :::");

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      if (artwork.accepted.length === 0) {
        alert("Acceptance is required.");
        event.preventDefault();
        event.stopPropagation();
      }

      refetch();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const dynemicRequire = (name, value) => {
    //console.log("dynemicRequire :::");

    if (name.toLowerCase().includes("water_theme") && value) {
      setWaterRequied("required");
    }

    if (name.toLowerCase().includes("destination_theme") && value) {
      setDestinationRequied("required");
    }

    if (name.toLowerCase().includes("cultural_theme") && value) {
      setCulturalRequied("required");
    }
  };

  const onChangeHandler = (event) => {
    //console.log("onChangeHandler :::", event);

    const { name, value } = event;

    //console.log("onChangeHandler :::", name, value);

    dynemicRequire(name, value);

    //console.log("onChangeHandler :::", name, value);

    setArtwork((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleFileChange = (event) => {
    //console.log("handleFileChange :::");

    const { name, files } = event;

    const MIN_FILE_SIZE = 56000; // 50kb
    const MAX_FILE_SIZE = 10240000; // 10MB

    const cancel = !files.length;
    if (cancel) return;

    const [{ size, name1 }] = files;
    //console.log(size);

    if (size > MIN_FILE_SIZE && size < MAX_FILE_SIZE) {
      dynemicRequire(name, files[0].name);

      if (files) {
        setArtwork((prev) => {
          return { ...prev, [name]: files[0] };
        });
      }
    } else {
      if (name === "water_theme_file") {
        refWaterFile.current.value = "";
      }

      if (name === "destination_theme_file") {
        refDestinationFile.current.value = "";
      }

      if (name === "cultural_theme_file") {
        refCulturalFile.current.value = "";
      }
      alert("file size between 50KB to 10mb.");
    }
  };

  const resetForm = (type) => {
    //console.log("resetForm :::");

    if (type === "water") {
      resetArtwor("water");
      setWaterRequied(false);
      refWaterFile.current.value = "";
    }

    if (type === "destination") {
      resetArtwor("destination");
      setDestinationRequied(false);
      refDestinationFile.current.value = "";
    }

    if (type === "cultural") {
      resetArtwor("cultural");
      setCulturalRequied(false);
      refCulturalFile.current.value = "";
    }
  };

  const resetArtwor = (type) => {
    //console.log("resetArtwor :::");

    let newArtwork = artwork;

    newArtwork[type + "_theme_file"] = "";
    newArtwork[type + "_theme_title"] = "";
    newArtwork[type + "_theme_location"] = "";
    newArtwork[type + "_theme_year"] = "";
    newArtwork[type + "_theme_camera"] = "";
    newArtwork[type + "_theme_description"] = "";

    setArtwork(newArtwork);
  };

  //console.log("artwork", artwork);

  return (
    <section className="form-section">
      <div className="container-fluid p-5">
        <div className="row m-0 pt-3 justify-content-center">
          <div className="col-lg-8 col-md-8 col-sm-612 text-white">
            <AlertComponent msg={message} variant={messageVariant} />

            <h1 className="text-uppercase border-bottom mb-4">
              Submit your work
            </h1>

            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Row>
                <Col sm={12} md={6}>
                  <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={191}
                      placeholder="Enter your name"
                      required
                      onChange={(e) => onChangeHandler(e.target)}
                      name="participant_name"
                      value={artwork.participant_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                  <Form.Group className="mb-3" controlId="formMobileNumber">
                    <Form.Label>Mobile Number</Form.Label>

                    <InputGroup className="mb-2">
                      <CountryCodeOption
                        refCountryCodeSelect={refCountryCodeSelect}
                        onChangeHandlerchild={onChangeHandler}
                      />
                      <Form.Control
                        type="text"
                        maxLength={191}
                        placeholder="Enter your mobile number"
                        required
                        onChange={(e) => onChangeHandler(e.target)}
                        name="participant_mobile"
                        value={artwork.participant_mobile}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      Please enter your mobile number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col sm={12} md={12}>
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      required
                      onChange={(e) => onChangeHandler(e.target)}
                      name="participant_email"
                      value={artwork.participant_email}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <h4 className="mb-4">Maximum one entry per theme.</h4>

              <Row>
                <Col className="verticalLine" sm={12} lg={4}>
                  <h6 className="mb-4 d-flex justify-content-between">
                    Water theme photograph
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip">
                          <strong>Click here to clear form</strong>
                        </Tooltip>
                      }
                    >
                      <Button variant="light" size="sm">
                        <FaRedoAlt onClick={() => resetForm("water")} />
                      </Button>
                    </OverlayTrigger>
                  </h6>

                  <Form.Group controlId="formWTPFile" className="mb-3">
                    <Form.Control
                      type="file"
                      accept=".png,.jpg,.jpeg,.dsc"
                      onChange={(e) => handleFileChange(e.target)}
                      name="water_theme_file"
                      required={waterRequied}
                      ref={refWaterFile}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formWTPTitle">
                    <Form.Label>Title (30 Characters)</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      placeholder="Enter title"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="water_theme_title"
                      required={waterRequied}
                      value={artwork.water_theme_title}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formWTPLocation">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={191}
                      placeholder="Enter location name"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="water_theme_location"
                      required={waterRequied}
                      value={artwork.water_theme_location}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formWTPYear">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      type="number"
                      min="1901"
                      max="2024"
                      step={1}
                      placeholder="Enter year"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="water_theme_year"
                      required={waterRequied}
                      value={artwork.water_theme_year}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formWTPCamera">
                    <Form.Label>Camera Device Used</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={191}
                      placeholder="Enter camera device used"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="water_theme_camera"
                      required={waterRequied}
                      value={artwork.water_theme_camera}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formWTPDescription">
                    <Form.Label>Description (100 Characters)</Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength={100}
                      rows={4}
                      placeholder="Enter Description of the image"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="water_theme_description"
                      required={waterRequied}
                      value={artwork.water_theme_description}
                    />
                  </Form.Group>
                </Col>

                <Col className="verticalLine" sm={12} lg={4}>
                  <h6 className="mb-4 d-flex justify-content-between">
                    Destinations theme photograph
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip">
                          <strong>Click here to clear form</strong>
                        </Tooltip>
                      }
                    >
                      <Button variant="light" size="sm">
                        <FaRedoAlt onClick={() => resetForm("destination")} />
                      </Button>
                    </OverlayTrigger>
                  </h6>

                  <Form.Group controlId="formDTPFile" className="mb-3">
                    <Form.Control
                      type="file"
                      accept=".png,.jpg,.jpeg,.dsc"
                      onChange={(e) => handleFileChange(e.target)}
                      name="destination_theme_file"
                      required={destinationRequied}
                      ref={refDestinationFile}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="forDTPTitle">
                    <Form.Label>Title (30 Characters)</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      placeholder="Enter title"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="destination_theme_title"
                      required={destinationRequied}
                      value={artwork.destination_theme_title}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="forDTPLocation">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={191}
                      placeholder="Enter location name"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="destination_theme_location"
                      required={destinationRequied}
                      value={artwork.destination_theme_location}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="forDTPYear">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      type="number"
                      min="1901"
                      max="2024"
                      step={1}
                      placeholder="Enter year"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="destination_theme_year"
                      required={destinationRequied}
                      value={artwork.destination_theme_year}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="forDTPCamera">
                    <Form.Label>Camera Device Used</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={191}
                      placeholder="Enter camera device used"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="destination_theme_camera"
                      required={destinationRequied}
                      value={artwork.destination_theme_camera}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="forDTPDescription">
                    <Form.Label>Description (100 Characters)</Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength={100}
                      rows={4}
                      placeholder="Enter Description of the image"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="destination_theme_description"
                      required={destinationRequied}
                      value={artwork.destination_theme_description}
                    />
                  </Form.Group>
                </Col>

                <Col className="verticalLine" sm={12} lg={4}>
                  <h6 className="mb-4 d-flex justify-content-between">
                    Cultural theme photograph
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip">
                          <strong>Click here to clear form</strong>
                        </Tooltip>
                      }
                    >
                      <Button variant="light" size="sm">
                        <FaRedoAlt onClick={() => resetForm("cultural")} />
                      </Button>
                    </OverlayTrigger>
                  </h6>

                  <Form.Group controlId="formCTPFile" className="mb-3">
                    <Form.Control
                      type="file"
                      accept=".png,.jpg,.jpeg,.dsc"
                      onChange={(e) => handleFileChange(e.target)}
                      name="cultural_theme_file"
                      required={culturalRequied}
                      ref={refCulturalFile}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCTPTitle">
                    <Form.Label>Title (30 Characters)</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      placeholder="Enter title"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="cultural_theme_title"
                      required={culturalRequied}
                      value={artwork.cultural_theme_title}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCTPLocation">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={191}
                      placeholder="Enter location name"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="cultural_theme_location"
                      required={culturalRequied}
                      value={artwork.cultural_theme_location}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCTPYear">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      type="number"
                      min="1901"
                      max="2024"
                      step={1}
                      placeholder="Enter year"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="cultural_theme_year"
                      required={culturalRequied}
                      value={artwork.cultural_theme_year}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCTPCamera">
                    <Form.Label>Camera Device Used</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={191}
                      placeholder="Enter camera device used"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="cultural_theme_camera"
                      required={culturalRequied}
                      value={artwork.cultural_theme_camera}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCTPDescription">
                    <Form.Label>Description (100 Characters)</Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength={100}
                      rows={4}
                      placeholder="Enter Description of the image"
                      onChange={(e) => onChangeHandler(e.target)}
                      name="cultural_theme_description"
                      required={culturalRequied}
                      value={artwork.cultural_theme_description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Col className="text-end form-btn">
                {!isLoading ? (
                  <>
                    <Form.Check
                      required
                      type={"checkbox"}
                      id={`default-checkbox`}
                      label={`By submitting your entry you agree that you accept the rules of The Artoreal Photography Awards, Summer 2024 mentioned on this page`}
                      onChange={(e) => onChangeHandler(e.target)}
                      name="accepted"
                    />
                    <Button variant="light" type="submit" className="px-5">
                      Submit
                    </Button>
                  </>
                ) : (
                  <img src={loading} alt="loading" className="loadingLoading" />
                )}
              </Col>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubmitWork;
