function AwardCard({image, title, price, countertext, classes=""}) {
  return (
    <div className="col-lg-2 col-md-4 col-sm-12 card-block mb-4">
      <div className={"card text-black align-items-center bg-transparent border-0 " +classes}>
        <img src={image} className="card-img-top w-50" alt="1 price" />
        <div className="card-body">
          <div className="text-center">
            <h5 className="card-title text-capitalize">{title}</h5>
            <p className="m-0 fw-bold text-capitalize" dangerouslySetInnerHTML={{__html: price}}></p>
            <p className="m-0 text-muted text-capitalize">({countertext})</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AwardCard;
