import ThemeCard from "./ThemeCard";
import theme1 from "./assets/Theme1.png";
import theme2 from "./assets/Theme2.png";
import theme3 from "./assets/Theme3.png";

function Theme() {
  return (
    <section className="theme award-section">
      <div className="container-fluid p-5">
        <div className="row pt-3 justify-content-center">
          <h1 className="section-title text-center text-uppercase">Themes</h1>

          <ThemeCard
            image={theme1}
            title={"Water"}
            tag={"Rain, Monsoon, River, Lake, Sea"}
          />

          <ThemeCard
            image={theme2}
            title={"Destinations"}
            tag={
              "Interesting, exotic, adventure or off-road to travel and experience"
            }
          />

          <ThemeCard
            image={theme3}
            title={"Cultural"}
            tag={"Divinity, tradition, spiritual, festival, heritage"}
            classes={"no-border last"}
          />
        </div>
      </div>
    </section>
  );
}

export default Theme;
