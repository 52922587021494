import { Accordion } from "react-bootstrap";
import commonUrl from "services/urls";

function Rule() {
  return (
    <section className="rule-section">
      <div className="container-fluid p-5">
        <div className="row m-0 pt-3 justify-content-center">
          <div className="col-12">
            <h1 className="mb-3 text-center">
              Rules of The Artoreal Photography Awards
            </h1>
            <h1 className="text-center">Summer 2024</h1>

            <Accordion className="mb-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <b>Who can participate?</b>
                </Accordion.Header>
                <Accordion.Body>
                  Any professional, amateur, or passionate photographer
                  worldwide.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <b>Competition themes: 3 Themes</b>
                </Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>
                      <b>Water:</b> Rain, Monsoon, Monsoon landscapes,River, Lake, Sea
                    </li>
                    <li>
                      <b>Cultural:</b> Divinity, tradition, spiritual, festival,
                      heritage
                    </li>
                    <li>
                      <b>Destinations:</b> Interesting, exotic, adventure or
                      off-road to travel and experience
                    </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <b>How can I participate in the Awards?</b>
                </Accordion.Header>
                <Accordion.Body>
                  Submit your entry in the form below. Mention the title of your
                  picture, description, device and brand used to shoot the
                  picture(s), location, and capture date.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <b>What are the specifications for the entries?</b>
                </Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>
                      The entries are expected to be short and NOT Digitally
                      created.
                    </li>
                    <li>
                      JPG,PNG or DSC format. Good Quality. File Size between
                      50KB to 10MB.{" "}
                    </li>
                    <li>
                      The photos should not show any recognizable humans or
                      private properties.
                    </li>
                    <li>
                      The photos shall not depict violence, gender
                      discrimination, vulgarity, frontal nudity, banners,
                      posters, political social or religious messaging, use of
                      cigarettes, drugs or liquor.
                    </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <b>How many entries can I submit?</b>
                </Accordion.Header>
                <Accordion.Body>
                  You can submit upto 1 photo in each theme.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <b>You represent and warrant that</b>
                </Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>
                      The Photographs are original and that you own the rights
                      to them.
                    </li>

                    <li>
                      The Photographs do not violate the rights of any third
                      party.
                    </li>

                    <li>
                      The Photographs and Information you submit are not created
                      by an AI robot.
                    </li>

                    <li>
                      The photos submitted are your initial submission. That is,
                      they have not been used before to participate in any
                      photography-related competitions, events and/or
                      exhibitions.
                    </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <b>Result Announcement</b>
                </Accordion.Header>
                <Accordion.Body>
                  The results will be announced after 4th August 2024 on this page. Winners and Runners-Up will be notified via email.
                  <ul className="pt-2 list-style-type-lower-alpha">
                    <li>
                      Currency conversion rate of the day on which results are
                      announced will apply
                    </li>
                    <li>
                      The decision of Artoreal Judges will be final and no
                      explanation will be given for their choice of winners
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <b>Consent and Copyright</b>
                </Accordion.Header>
                <Accordion.Body>
                  By submitting your photograph(s) into the competition, you
                  grant to artoreal.com the non-commercial non-exclusive right to
                  use your images for promotion of the ARTOREAL Photography Awards
                  online photo competitions and for promotion of the artoreal.com
                  and Artoreal.com platforms both platforms owned by Nihilent
                  Ltd and its subsidiaries in any media. Should artoreal.com want
                  to use awarded images for commercial publications, this will
                  only be done with prior written consent by the photographer.
                  The photographer will be credited whenever possible. Entrants
                  must not infringe on the privacy rights, copyright or other
                  rights of any person.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <b>Exclusions and Modification of prizes</b>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="list-style-type-lower-alpha">
                    <li>
                      artoreal.com reserves the right to exclude entrants and
                      withhold prizes for violating any of these rules. Should
                      the quality of entries fall below the standard required we
                      reserve the right not to award prizes
                    </li>
                    <li>
                      artoreal.com reserves the right to change the number of
                      prizes in each category based on the number and quality of
                      entries, and/or not to give any prize in any category
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <b>Indemnity</b>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="list-style-type-lower-alpha">
                    <li>
                      artoreal.com is not responsible for failure of receipt of
                      any photographs entered in the competition.
                    </li>
                    <li>
                      Entrants indemnify artoreal.com and their sponsors of any
                      responsibility in respect to damage and loss to entrant’s
                      belongings; dispute regarding ownership and claims arising
                      therefrom; death or injury as a result of the competition.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  <b>Any Questions?</b>
                </Accordion.Header>
                <Accordion.Body>
                  Send your queries re the contest by email to{" "}
                  <a href="mailto:support@artoreal.com">support@artoreal.com</a>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Rule;
