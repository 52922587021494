const PRODUCTION_LINK = {
  base_url: "https://contestsapi.artoreal.com",
  media_url: "https://contestsapi.artoreal.com/",
  artoreal_terms_url: "https://www.artoreal.com/rulesandpolicy",
};


const DEVELOPMENT_LINKS = {
  base_url: "https://contestsapi.artoreal.com",
  media_url: "https://contestsapi.artoreal.com/",
  artoreal_terms_url: "https://www.artoreal.com/rulesandpolicy",
};

let defaultLinkExporter = null;
if (process.env.REACT_APP_STAGE === "prod") {
  defaultLinkExporter = PRODUCTION_LINK;
} else {
  defaultLinkExporter = DEVELOPMENT_LINKS;
}

export default defaultLinkExporter;
