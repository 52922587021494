import React, { useCallback, useState } from "react";
import "react-dropdown/style.css";
import "./Shortlist/shortlist.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Button } from "react-bootstrap";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import {
  finalShortList,
  getContestNames,
  getArtworks,
  getShTotal,
  newShortList,
  updatePosition,
  getPosition,
} from "services/apiCall";
import AlertComponent from "component/Alert";
import { Redirect } from "react-router-dom";

import { Link } from "react-router-dom";
const Dashboard = () => {
  const queryClient = useQueryClient();

  const [message, setMessage] = useState("");
  const [comment, setComment] = useState("");
  const [current, setCurrent] = useState(0);
  const [contestAndShortlistSelect, setContestData] = useState({});
  const onCloseClick = React.useCallback(() => {
    setMessage("");
  }, []);
  const onCommentChange = React.useCallback(({ target: { value } }) => {
    setComment(value);
  }, []);
  React.useEffect(() => {
    setComment("");
  }, [current]);
  const { data: result } = useQuery("getContestNames", getContestNames, {
    staleTime: Infinity,
  });

  const finalShortlistedData = undefined;

  React.useEffect(() => {
    setCurrent(0);
    // refetchFinalList();
  }, [contestAndShortlistSelect]);

  const CollectionId = result?.find(
    (a) => a.CollectionName === contestAndShortlistSelect.contest
  )?.CollectionId;

  const getArtworkMemo = React.useCallback(() => {
    if (
      !Object.values(contestAndShortlistSelect).every((a) => a !== "Select") ||
      !CollectionId ||
      !contestAndShortlistSelect.level
    ) {
      return;
    }
    return getArtworks(+CollectionId, +contestAndShortlistSelect.level);
  }, [contestAndShortlistSelect, CollectionId]);

  const { data: artworks, isLoading } = useQuery(
    ["getArtworks", contestAndShortlistSelect],
    getArtworkMemo
  );

  const getPositionMemo = React.useCallback(() => {
    if (
      !Object.values(contestAndShortlistSelect).every((a) => a !== "Select") ||
      !CollectionId ||
      !contestAndShortlistSelect.level
    ) {
      return;
    }
    return getPosition(+CollectionId, +contestAndShortlistSelect.level);
  }, [contestAndShortlistSelect, CollectionId]);

  useQuery(["getPosition", contestAndShortlistSelect], getPositionMemo, {
    onSuccess: (data) => {
      setCurrent(+data);
    },
  });
  //getPosition

  const logOut = React.useCallback(() => {
    localStorage.clear();
  }, []);

  const getTotalMemo = React.useCallback(() => {
    if (
      !Object.values(contestAndShortlistSelect).every((a) => a !== "Select")
    ) {
      return;
    }
    if (!CollectionId || !contestAndShortlistSelect.level) {
      return;
    }
    return getShTotal(+CollectionId, +contestAndShortlistSelect.level);
  }, [CollectionId, contestAndShortlistSelect]);

  const { data: currentData } = useQuery(
    ["getShTotal", contestAndShortlistSelect],
    getTotalMemo
  );

  const shortlistMutation = useMutation(
    (payload) => {
      return newShortList(payload);
    },
    {
      onSuccess: () => {
        setMessage("Shortlisted artwork successfully");
        queryClient.invalidateQueries("getArtworks");
        queryClient.invalidateQueries("getShTotal");
      },
    }
  );

  const singleShortlistArtwork = () => {
    const ArtworkId = artworks[current].ArtworkId;
    const payload = {
      Comment: comment || "NA",
      SortlistId: +contestAndShortlistSelect.level,
      ArtworkId,
    };
    setComment("");
    shortlistMutation.mutate(payload);
  };

  const finalShortListMutation = useMutation((payload) => {
    return finalShortList(payload);
  });

  const handleFinal = useCallback(
    (e) => {
      e.preventDefault();
      const shortlistId = +contestAndShortlistSelect.level;
      const payload = {
        CollectionId,
      };
      try {
        if (shortlistId < 3) {
          handleSelect({
            target: {
              name: "level",
              value: shortlistId + 1,
            },
          });
        } else {
          finalShortListMutation.mutate(payload);
        }
      } catch (e) {}
    },
    [contestAndShortlistSelect, CollectionId, finalShortListMutation]
  );

  const NextSlide = async (e) => {
    e.preventDefault();
    const ShortlistId = +contestAndShortlistSelect.level;
    const Position = current + 1;

    await updatePosition({
      CollectionId,
      ShortlistId,
      Position,
    });
    setCurrent((prev) => {
      return prev === artworks.length - 1 ? 0 : prev + 1;
    });
  };

  const PrevSlide = () => {
    setCurrent((prev) => {
      return prev === artworks.length - 1 || prev === 0 ? 0 : prev - 1;
    });
  };

  const handleSelect = ({ target: { value, name } }) => {
    setContestData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  if (artworks?.length < current) {
    setCurrent(0);
  }

  if (!localStorage.token) {
    return <Redirect to="/" />;
  }

  if (!result) {
    return (
      <div className="emptySelectBox">
        <h5>Loading some data, Please wait.</h5>
      </div>
    );
  }

  return (
    <React.Fragment>
      <AlertComponent
        msg={message}
        onCloseClick={onCloseClick}
        variant="success"
      />

      <nav>
        <div className="navLeftDiv">
          <div className="">
            <strong>Collection </strong>
            <select
              name="contest"
              onChange={handleSelect}
              value={contestAndShortlistSelect?.contest}
            >
              <option>Select</option>
              {result.map((data) => (
                <option title={data.userId} key={data.CollectionName}>
                  {data.CollectionName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <strong>Shortlist level </strong>
            <select
              name="level"
              onChange={handleSelect}
              value={contestAndShortlistSelect?.level}
            >
              <option>Select</option>

              {[1, 2, 3].map((data) => (
                <option title={data} key={data}>
                  {data}
                </option>
              ))}
            </select>
          </div>
          {typeof currentData?.ShortCount === "number" && (
            <span>
              Current artwork: &nbsp;
              {!!artworks?.length ? current + 1 : 0}
            </span>
          )}
          {typeof currentData?.ShortCount === "number" && (
            <span>
              Remaining artwork: &nbsp;
              {!!artworks?.length ? artworks?.length - (current - 1) : 0}
            </span>
          )}
          {typeof currentData?.ShortCount === "number" && (
            <span> Shortlisted artwork: {currentData.ShortCount}</span>
          )}
          {typeof currentData?.TotalCount === "number" && (
            <span>
              {" "}
              Total artworks:{" "}
              {!!currentData.TotalCount ? currentData.TotalCount + 1 : 0}
            </span>
          )}
        </div>
        <div>
          <Link to="login" onClick={logOut}>
            <Button variant="outline-primary">Logout</Button>
          </Link>
        </div>
      </nav>
      {!!contestAndShortlistSelect.level &&
        !!currentData &&
        !!artworks?.length &&
        !finalShortlistedData?.length && (
          <div>
            <center>
              <hr />
              <section className="slider">
                {artworks.map((image, index) => {
                  return (
                    <React.Fragment key={image.ArtworkId}>
                      {index === current && (
                        <div
                          className={
                            index === current ? "slide-active" : "slide"
                          }
                        >
                          <img
                            src={image.ArtworkUrl}
                            alt="img"
                            className="carosalImage"
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </section>
              <div className="actionButtonDiv">
                <FaArrowAltCircleLeft
                  className="left-arrow"
                  onClick={PrevSlide}
                />
                <Button onClick={singleShortlistArtwork}>
                  {" "}
                  Shortlist Artwork
                </Button>
                <FaArrowAltCircleRight
                  className="right-arrow"
                  onClick={NextSlide}
                />
              </div>
              <textarea
                rows="2"
                cols="50"
                name="comment"
                placeholder="Comment"
                value={comment}
                onChange={onCommentChange}
              />

              <hr />
            </center>
            <div className="bottomDiv">
              <Button className="btn btn-dark" onClick={handleFinal}>
                {+contestAndShortlistSelect.level < 3
                  ? "Submit this Level"
                  : "Finalize and Submit"}{" "}
              </Button>
            </div>
          </div>
        )}
      {!contestAndShortlistSelect.level && !artworks?.length && (
        <div className="emptySelectBox">
          <h5>Please select Artwork collection and shortlist level.</h5>
        </div>
      )}
      {!!contestAndShortlistSelect.level && !artworks?.length && !isLoading && (
        <div className="emptySelectBox">
          <h5>
            Sorry, There is no artwork in selected Collection and/or Shortlist
            Level
          </h5>
        </div>
      )}
      {!!finalShortlistedData?.length && (
        <div className="emptySelectBox">
          <h5>Thank you for Shortlisting artworks.</h5>
        </div>
      )}
    </React.Fragment>
  );
};
export default Dashboard;
