function ThemeCard({image, title, tag, classes=""}) {
  return (
    <div className="col-lg-4 col-md-4 col-sm-12 theme-block mb-4">
      <div className={"card text-black align-items-center bg-transparent border-0 " + classes}>
        <img src={image} className="card-img-top w-75" alt="1 price" />
        <div className="card-body">
          <div className="text-center">
            <h5 className="card-title text-capitalize">{title}</h5>
            <p className="m-0 fw-normal text-capitalize" dangerouslySetInnerHTML={{__html: tag}}></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThemeCard;
