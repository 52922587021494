import React from "react";
import { Alert } from "react-bootstrap";

const AlertComponent = React.memo(
  ({ msg, variant = "danger", onCloseClick }) => {
    const [myMessage, setMsg] = React.useState(msg);
    const closeAlert = React.useCallback(() => {
      setMsg("");
      onCloseClick();
    }, [setMsg, onCloseClick]);

    React.useEffect(() => {
      setMsg(msg);
    }, [msg]);

    console.log("myMessage.length", myMessage.length);
    
    return (
      <div className="alertPositionDiv">
        <Alert
          variant={variant}
          dismissible
          onClose={closeAlert}
          show={!!myMessage.length}
        >
          {myMessage}
        </Alert>
      </div>
    );
  }
);

export default AlertComponent;
