import React, { useState } from "react";
import loading from "assets/loading.gif";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { login } from "services/apiCall";
import { useQuery } from "react-query";
import AlertComponent from "component/Alert";
import { Redirect } from "react-router-dom";

export default function Login() {
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const loginApiQuery = React.useCallback(() => {
    return login(formData);
  }, [formData]);
  const loginSuccess = React.useCallback(
    ({ token, id, name }) => {
      localStorage.setItem("token", token);
      localStorage.setItem("id", id);
      localStorage.setItem("name", name);
      localStorage.setItem("logged_in_time", new Date().toDateString());
      setMessage("");
      history.replace("/dashboard");
    },
    [history]
  );
  const loginError = React.useCallback((e) => {
    if (e.response.status === 404) {
      setMessage("Wrong username or password!");
      return;
    }
    setMessage(e.message);
  }, []);

  const { isLoading, refetch } = useQuery("login", loginApiQuery, {
    enabled: false,
    retry: 1,
    onSuccess: loginSuccess,
    onError: loginError,
  });

  const formInputCallback = React.useCallback(({ target: { name, value } }) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }, []);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      refetch();
    },
    [refetch]
  );

  if (localStorage.token) {
    return <Redirect to={"/dashboard"} />;
  }
  return (
    <div className="login-wrapper">
      <AlertComponent msg={message} />
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <h2 className="Login">Artwork curation</h2>
            <Form onSubmit={handleSubmit} className="col-md-8 p-2">
              <Form.Group size="lg" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  autoFocus
                  type="text"
                  onChange={formInputCallback}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  onChange={formInputCallback}
                />
              </Form.Group>
              <br />
              <div>
                {!isLoading ? (
                  <Button size="lg" type="submit" className="loginButton">
                    Login
                  </Button>
                ) : (
                  <img src={loading} alt="loading" className="loadingLoading" />
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
