import { Calendar2Event } from "react-bootstrap-icons";

function DeadLine() {
  return (
    <section className="deadline-section">
      <div className="container-fluid p-5">
        <div className="row m-0 pt-3 justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-6 text-white">
            <div className="text-center">
              <div>
                <Calendar2Event size={64} />
              </div>
              <h2>Deadline</h2>
              <p>April 30<sup>th</sup>, 2024 - 11:59 pm (UTC+5:30)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DeadLine;
