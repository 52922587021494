import Container from 'react-bootstrap/Container';
import TopNavbar from './TopNavbar';
import Header from './Header';
import Award from './Award';
import DeadLine from './DeadLine';
import Theme from './Theme';
import Rule from './Rule';
import SubmitWork from './SubmitWork';
import "./contest.css";

function Index() {
  return (
    <div className="container-fluid p-0 contest-box">
        <TopNavbar/>
        <Header/>
        <Award />
        <DeadLine />
        <Theme />
        <Rule />
        <SubmitWork />
    </div>
  );
}

export default Index;